<template>
  <div :class="['auth-page d-flex fill-width fill-height blur-bg']">
    <div class="auth-wrap d-flex justify-center align-center fill-width py-12">
      <div class="auth d-flex flex-column pa-11 fill-width rounded gray-0">
        <div>
          <router-link :to="{name: 'WelcomeLanding'}" class="mb-12 d-inline-block">
            <UIImage width="130" :name-path="`logo.svg`"/>
          </router-link>

          <div class="mb-2 text-captions-1 gray-60--text">Hello, and welcome! 👋</div>

          <h3 class="mb-8 text-title-2 gray-100--text">Login as admin</h3>


          <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <v-form @submit.prevent="handleSubmit(submit)" class="relative">
              <div v-if="isLoading"
                   class="d-flex justify-center align-center fill-width fill-height absolute inset-0 white"
                   style="z-index: 2">
                <v-progress-circular
                  :size="100"
                  color="accent"
                  indeterminate/>
              </div>

              <ValidationProvider class="input-wrap mb-4" mode="eager" tag="div"
                                  name="your login" vid="username" rules="required" v-slot="{ errors }">
                <div class="mb-2 text-body font-weight-light gray-100--text">Login</div>
                <v-text-field
                  v-model="form.username"
                  outlined
                  hide-details
                  autocomplete="username"
                  :disabled="isLoading"
                  :error="!!errors.length"
                  placeholder="Login"
                />
                <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
              </ValidationProvider>

              <ValidationProvider class="input-wrap mb-10" mode="eager" tag="div"
                                  name="your password" vid="password" rules="required" v-slot="{ errors }">
                <div class="mb-2 text-body font-weight-light gray-100--text">Password</div>
                <v-text-field
                  v-model="form.password"
                  type="password"
                  outlined
                  hide-details
                  @keydown.space.prevent
                  autocomplete="new-password"
                  :disabled="isLoading"
                  :error="!!errors.length"
                  placeholder="Password"
                />
                <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
              </ValidationProvider>

              <UiBtn
                type="submit"
                width="100%"
                height="40"
                color="accent"
                class="mb-3"
                :disabled="isFormFieldEmpty || invalid"
              >
                Sign in
              </UiBtn>
            </v-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIImage from "@/components/UI/UIImage.vue";
import UiBtn from "@/components/UI/UiBtn.vue";

export default {
  name: 'LoginPage',
  components: {UiBtn, UIImage},
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
    }
  },
  computed: {
    isFormFieldEmpty() {
      return Object.keys(this.form).some(key => this.form[key] === '');
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.form)
    }
  }
}
</script>

<style scoped lang="scss">
.auth-page {
  background: transparent url("~@/assets/images/auth/bg-pattern.png") repeat;

  &.blur-bg {
    background: linear-gradient(90deg, #DCDDDE 14.08%, rgba(255, 255, 255, 0) 100%), url("~@/assets/images/auth/bg-pattern.png") repeat;
  }
}


.auth {
  max-width: 444px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.09);
  margin-left: 12px;
  margin-right: 12px;
}


::v-deep .input-wrap .v-input__slot {
  min-height: 45px;

  input {
    font-size: 12px;
    color: var(--v-gray-60-base);
  }
}
</style>
