<template>
  <div class="fill-height fill-width">
    <AdminAuth v-if="!getAdminToken" @submit="submit" :isLoading="isLoading"/>

    <template v-if="getAdminToken">
      <div v-if="isLoading" class="d-flex justify-center pt-16">
        <v-progress-circular
            :size="100"
            color="accent"
            indeterminate
        />
      </div>
      <div v-else class="d-flex flex-column fill-height">
        <PageHeader>
          <div class="d-flex justify-space-between align-center space-x-4">
            <h1 class="text-heading text-truncate">Users</h1>
          </div>
        </PageHeader>

        <v-container>
          <div class="d-flex align-center justify-space-between mb-5 mt-2">
            <v-spacer/>
            <div class="d-flex align-center">
              <UiSearch style="width: 194px" @onSearch="search = $event"/>
            </div>
          </div>

          <v-virtual-scroll :bench="10" :items="filteredUsers" :item-height="72" :height="height" class="mr-n6">
            <template v-slot:default="{ item:user }">
              <UiListCard
                  :key="user.id"
                  :item="user"
                  :selectable="false"
                  :selected="user.id === getAuthId"
                  class="user-item mb-3 mr-3 relative small"
                  :class="{ 'is-loading': user.loading }"
              >

                <template v-slot:body>
                  <div class="user-item-section-wrap d-flex align-center">
                    <div class="user-item-section px-5 mr-auto">
                      <div class="d-flex align-center mr-auto overflow-hidden" style="max-width: 410px">
                        <UiAvatar :text="fullName(user)" :src="user.avatar ? user.avatar.url : ''" size="22"
                                  text-class="text-captions-1 gray-80--text"
                                  class="mr-2"/>
                        <div class="text-body gray-80--text overflow-hidden">
                          <v-tooltip top color="rgba(47, 49, 53, 0.7)">
                            <template #activator="{attrs, on}">
                              <div class="font-weight-semi-bold text-truncate" v-bind="attrs" v-on="on">{{
                                  fullName(user)
                                }}
                              </div>
                            </template>
                            <div class="text-captions-1">
                              {{ fullName(user) }}
                            </div>
                          </v-tooltip>

                        </div>
                      </div>
                    </div>

                    <div class="user-item-section  border-left px-5">
                      <v-tooltip top color="rgba(47, 49, 53, 0.7)">
                        <template #activator="{attrs, on}">
                          <div class="text-body gray-60--text text-truncate" v-bind="attrs" v-on="on">{{
                              user.email
                            }}
                          </div>
                        </template>
                        <div class="text-captions-1">
                          {{ user.email }}
                        </div>
                      </v-tooltip>

                    </div>

                    <div class="user-item-section fill-width d-flex border-left px-5">
                      <UiBtn v-if="user.id !== getAuthId" :loading="user.loading" @click="loginAsUser(user)"
                             color="primary"
                             class="ml-auto px-6">Start take over
                      </UiBtn>
                      <UiBtn v-else @click="closeSession" color="error" class="ml-auto px-6">Close session</UiBtn>
                    </div>
                  </div>

                </template>
              </UiListCard>
            </template>
          </v-virtual-scroll>
        </v-container>
      </div>
    </template>


  </div>

</template>

<script>
import AdminAuth from "@/views/admin/AdminAuth.vue";
import UiAvatar from "@/components/UI/UiAvatar.vue";
import {mapActions, mapGetters} from "vuex";
import PageHeader from "@/components/layout/PageHeader.vue";
import UiSearch from "@/components/UI/UiSearch.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import UiListCard from "@/components/UI/UiListCard.vue";

export default {
  name: "TakeOverAccount",
  components: {
    UiBtn,
    UiListCard,
    UiSearch,
    PageHeader,
    UiAvatar, AdminAuth
  },
  data() {
    return {
      isLoading: false,
      userList: [],
      search: ''
    }
  },
  computed: {
    ...mapGetters(['getAdminToken', 'getAuthId', 'isTempAuth']),
    filteredUsers() {
      return this.search ? this.searchArrayByText(this.userList, this.search) : this.userList
    },
    height() {
      const pageHeaderAndSearchHeight = 152
      return this.$vuetify.breakpoint.height - this.$vuetify.application.top - pageHeaderAndSearchHeight
    },

    fullName() {
      return user => {
        const firstName = user.first_name || ''
        const lastName = user.last_name || ''
        return `${firstName} ${lastName}`.trim()
      }
    }
  },
  methods: {
    ...mapActions(["setTempAuth", 'setAdminToken', 'logoutAdmin', 'logoutTempAuth']),
    searchArrayByText(array, searchText) {
      searchText = searchText.toLowerCase();

      return array.filter(item =>
          (item.email || '').toLowerCase().includes(searchText) ||
          (item.first_name || '').toLowerCase().includes(searchText) ||
          (item.last_name || '').toLowerCase().includes(searchText)
      );
    },
    async submit(data) {
      const base64Token = this.encodeCredentials(data.username, data.password)
      try {
        await this.getUsers(base64Token)
        this.setAdminToken(base64Token)
      } catch (e) {
        this.$toast.open({
          message: 'Incorrect login or password',
          type: 'error',
          position: 'top-right'
        });
      }
    },

    async getUsers(token) {
      this.isLoading = true;

      try {
        const res = await this.$api.auth.getAllUsers({
          skipResponseInterceptor: true,
          headers: {
            Authorization: `Basic ${token}`
          }
        })
        this.userList = (res.data || []).map(user => {
          return {
            ...user, loading: false
          }
        })
      } catch (error) {
        if (error?.response?.status === this.$config.http.UNAUTHORIZED) {
          this.logoutAdmin()
          throw error
        } else {
          this.$toast.open({
            message: 'Oops! Something went wrong!',
            type: 'error',
            position: 'top-right'
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async loginAsUser(user) {
      user.loading = true
      try {
        const res = await this.$api.auth.impersonateUser(user.id, {
          skipResponseInterceptor: true,
          headers: {
            Authorization: `Basic ${this.getAdminToken}`
          }
        })
        await this.setTempAuth(res || null)
        window.location.href = '/'
      } catch (error) {
        if (error?.response?.status === this.$config.http.UNAUTHORIZED) {
          this.logoutAdmin()
        } else {
          this.$toast.open({
            message: 'Oops! Something went wrong!',
            type: 'error',
            position: 'top-right'
          });
        }
      } finally {
        user.loading = false
      }
    },
    encodeCredentials(username, password) {
      const credentials = `${username}:${password}`;
      return btoa(credentials);
    },
    async closeSession() {
      if(this.isTempAuth) {
        this.logoutTempAuth()
      } else {
        await this.$api.auth.logout();
        await this.$store.dispatch('setAuth', null);
      }
      window.location.href = '/admin'
    },
  },
  mounted() {
    if (this.getAdminToken) {
      this.getUsers(this.getAdminToken)
    }
  }
}
</script>
<style scoped lang="scss">
:deep(.v-virtual-scroll) {
  max-width: initial;
}

.user-item {
  height: 60px;

  &.is-loading {
    pointer-events: none;
  }

  :deep(.ui-list-card__image) {
    display: none !important;
  }

  :deep(.ui-list-card__content) {
    max-width: 100%;
  }

  .user-item-section {
    width: 30%;
    word-break: break-all;

    &:last-child {
      width: 40%;
    }
  }

  .border-left::before {
    content: '';
    position: absolute;
    top: 18px;
    bottom: 18px;
    margin-left: -20px;
    border-left: 1px solid var(--v-gray-20-base);
  }
}
</style>
